import React, { FC, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  TrashIcon,
  CheckIcon,
  XCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  Popover,
  TextareaAutosize,
} from '@mui/material'
import { ToDoStatus } from 'types/graphql'
import type {
  CreateToDoMutation,
  CreateToDoMutationVariables,
  FindToDos,
  UpdateToDoMutation,
  UpdateToDoMutationVariables,
} from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import CustomBadge from 'src/components/Library/CustomBadge/CustomBadge'
import Loading from 'src/components/Library/Loading/Loading'
import PageHeaderIconButton from 'src/components/PageHeaderIconButton/PageHeaderIconButton'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import useLocalStorage from 'src/lib/hooks/UseLocalStorage'
import { useAuth } from 'src/Providers'

const QUERY = gql`
  query FindToDos {
    toDos {
      id
      createdAt
      updatedAt
      createdBy
      updatedBy
      text
      status
    }
  }
`

export type ToDosType = FindToDos['toDos'][0]

const CREATE_TO_DO_MUTATION = gql`
  mutation CreateToDoMutation($input: CreateToDoInput!) {
    createToDo(input: $input) {
      id
      createdAt
      updatedAt
      createdBy
      updatedBy
      text
      status
    }
  }
`
const UPDATE_TO_DO_MUTATION = gql`
  mutation UpdateToDoMutation($id: Int!, $input: UpdateToDoInput!) {
    updateToDo(id: $id, input: $input) {
      id
      createdAt
      updatedAt
      createdBy
      updatedBy
      text
      status
    }
  }
`

const ToDosList: FC = () => {
  const [enterValue, setEnterValue] = useState('')
  const [textValues, setTextValues] = useState({})

  const [toDos, setToDos] = useState<ToDosType[]>([])

  const showNotifications = useBoolean(false)

  const { trackEvent } = useAnalytics()

  const { currentUser } = useAuth()

  const membershipId = currentUser?.membershipData?.id

  const [storedToDosCountLocal, setStoredToDosCountLocal] =
    useLocalStorage<number>(`ToDoCount-${membershipId}`, 0)

  const [toDosLoaded, setToDosLoaded] = useState<boolean>(false)

  const [getToDos] = useLazyQuery(QUERY, {
    onCompleted: (data) => {
      setToDos(data.toDos)
      setStoredToDosCountLocal(
        data.toDos.filter((todo) => todo.status === 'IN_PROGRESS').length,
      )
      setToDosLoaded(true)
    },
  })

  // Handle popover events
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
    showNotifications.toggle()
    getToDos()
  }

  const [updateToDo] = useMutation<
    UpdateToDoMutation,
    UpdateToDoMutationVariables
  >(UPDATE_TO_DO_MUTATION, {
    errorPolicy: 'none', // Ensures errors are thrown and caught in try/catch
  })

  const [createToDo] = useMutation<
    CreateToDoMutation,
    CreateToDoMutationVariables
  >(CREATE_TO_DO_MUTATION, {
    errorPolicy: 'none', // Ensures errors are thrown and caught in try/catch
  })

  const createNewToDo = async (text: string) => {
    if (text.length === 0) return
    setEnterValue('')
    const castInput = {
      text: text,
      status: 'IN_PROGRESS' as ToDoStatus,
    }
    //client side update
    const dummyId = Date.now()
    const newTodo = {
      id: dummyId,
      text,
      status: 'IN_PROGRESS' as ToDoStatus,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: currentUser?.userData?.id,
      updatedBy: currentUser?.userData?.id,
    }
    const previousCount = storedToDosCountLocal
    setToDos((prev) => [newTodo, ...prev])
    setStoredToDosCountLocal((prev) => prev + 1)
    try {
      const newlyCreatedTodo = await createToDo({
        variables: { input: castInput },
      })
      const newTodo = newlyCreatedTodo.data?.createToDo
      //replace the todo list item with the newly created one
      setToDos((prev) => {
        return prev.map((todo) => {
          if (todo.id === dummyId) {
            return newTodo
          }
          return todo
        })
      })
    } catch (e) {
      toast.error('Something went wrong, please try again.')
      //revert the todo list item
      setToDos((prev) => prev.filter((todo) => todo.id !== dummyId))
      setStoredToDosCountLocal(previousCount)
    }
  }

  const updateToDoText = async (id: number, text: string) => {
    if (!id || id < 0 || id > 999_999_999) {
      toast.error('Some went wrong, please try again.')
      return
    }
    const castInput = {
      text: text,
    }
    const currentTodo = toDos.find((todo) => todo.id === id)
    const previousText = currentTodo?.text
    //client side update
    setToDos((prev) =>
      prev.map((todo) => (todo.id === id ? { ...todo, text } : todo)),
    )
    try {
      await updateToDo({ variables: { id, input: castInput } })
    } catch (e) {
      toast.error('Something went wrong, please try again.')
      //revert the text
      setToDos((prev) =>
        prev.map((todo) =>
          todo.id === id ? { ...todo, text: previousText } : todo,
        ),
      )
    }
  }

  const updateToDoStatus = async (id: number, status: ToDoStatus) => {
    if (!id || id < 0 || id > 999_999_999) {
      toast.error('Something went wrong, please try again.')
      return
    }
    const castInput = {
      status: status,
    }
    const currentTodo = toDos.find((todo) => todo.id === id)
    const previousStatus = currentTodo?.status
    const previousUpdatedAt = currentTodo?.updatedAt
    const previousStoredToDoCount = storedToDosCountLocal
    //client side update
    setToDos((prev) =>
      prev.map((todo) =>
        todo.id === id
          ? { ...todo, status, updatedAt: new Date().toISOString() }
          : todo,
      ),
    )
    setStoredToDosCountLocal((prev) => {
      if (status === 'ARCHIVED' && previousStatus === 'COMPLETED') {
        return prev
      } else if (status === 'ARCHIVED' && previousStatus === 'IN_PROGRESS') {
        return prev > 0 ? prev - 1 : prev
      } else if (status === 'IN_PROGRESS') {
        return prev + 1
      } else {
        return prev > 0 ? prev - 1 : prev // Only decrement if `prev > 0`
      }
    })
    try {
      await updateToDo({ variables: { id, input: castInput } })
    } catch (e) {
      toast.error('Something went wrong, please try again.')
      //revert the status
      setToDos((prev) =>
        prev.map((todo) =>
          todo.id === id
            ? { ...todo, status: previousStatus, updatedAt: previousUpdatedAt }
            : todo,
        ),
      )
      setStoredToDosCountLocal(previousStoredToDoCount)
    }
  }

  useEffect(() => {
    //update the text values when the toDos are loaded and updated
    if (toDosLoaded) {
      const textValuesCopy = { ...textValues }
      for (const toDo of toDos) {
        textValuesCopy[toDo.id] = toDo.text
      }
      setTextValues(textValuesCopy)
    }
  }, [toDos, toDosLoaded])

  return (
    <>
      <CustomBadge
        badgeContent={storedToDosCountLocal}
        data-testid="todos-badge"
        color="info"
      >
        <PageHeaderIconButton
          buttonDataTestId="todoExpandButton"
          onClick={(args) => {
            handleClick(args)

            trackEvent('Uncategorized', 'todos', {
              expand: true,
            })
          }}
        >
          <CheckIcon className="h-6 w-6" />
        </PageHeaderIconButton>
      </CustomBadge>
      <Popover
        className={'mt-3'}
        open={showNotifications.value}
        anchorEl={anchorEl}
        onClose={() => {
          showNotifications.toggle()
          trackEvent('Uncategorized', 'todos', {
            expand: false,
          })
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{
          root: 'shadow-none',
          paper:
            'rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50',
        }}
      >
        <Box className="z-5 w-96 max-w-full rounded-lg text-gray-700 shadow-none">
          <div className="mb-0 mt-4 flex justify-between border-b px-4">
            <h1 className={'mb-2 pb-2 text-sm font-semibold'}>To Do</h1>
            <XCircleIcon
              className="h-7 w-7 text-gray-400 hover:text-gray-700"
              onClick={(args) => {
                handleClick(args)

                trackEvent('Uncategorized', 'todos', {
                  expand: false,
                })
              }}
            />
          </div>
          <div className="todos-scrollbar-thumb z-50 max-h-[80vh] overflow-y-auto hover:overflow-x-hidden">
            <div className="mx-4 mt-4 flex items-center rounded-md border border-gray-200 bg-gray-100 p-2 hover:bg-gray-200">
              <PlusCircleIcon
                className="ml-2 h-5 w-5 bg-transparent text-gray-500"
                onClick={() => {
                  createNewToDo(enterValue)
                  setEnterValue('')
                }}
              />
              <TextareaAutosize
                id="todoNewInput"
                className="ml-3 w-full border-0 bg-transparent p-1 text-sm"
                style={{ resize: 'none' }}
                value={enterValue}
                placeholder="Add a new To Do"
                onChange={(e) => {
                  const newValue = e.target.value || ''
                  if (newValue !== '\n') {
                    setEnterValue(newValue)
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    const text = e.currentTarget.value || ''
                    createNewToDo(text.trim())
                  }
                }}
                // @william-stafflink: fix these props, they are non-optional
                onResize={undefined}
                onResizeCapture={undefined}
              />
            </div>
            {!toDosLoaded ? (
              <div className="p-4">
                <Loading />
              </div>
            ) : (
              <List dense={true} className="my-4">
                {toDos
                  .filter((toDo) => toDo.status !== 'ARCHIVED')
                  .map((item) => (
                    <ListItem
                      className="group flex w-full items-center justify-between gap-2 px-3 py-1 hover:bg-gray-100"
                      key={item.id}
                      dense={true}
                      disablePadding={true}
                      divider={true}
                    >
                      <Checkbox
                        size="small"
                        sx={{
                          color: '#bababa',
                          '&.Mui-checked': {
                            color: '#4f46e5',
                          },
                        }}
                        id={'todoItemDone-' + item.id}
                        name={'todoItemDone-' + item.id}
                        checked={item.status === 'COMPLETED'}
                        onChange={() =>
                          updateToDoStatus(
                            item.id,
                            item.status === 'COMPLETED'
                              ? 'IN_PROGRESS'
                              : 'COMPLETED',
                          )
                        }
                      />
                      <TextareaAutosize
                        className={
                          'w-full border-0 bg-transparent p-2 text-sm leading-4 text-gray-500 ' +
                          (item.status === 'COMPLETED' ? 'line-through' : '')
                        }
                        style={{ resize: 'none' }}
                        value={textValues[item.id]}
                        id={'todoItemTest-' + item.id}
                        placeholder="Todo..."
                        onChange={(newValue) => {
                          setTextValues((prev) => ({
                            ...prev,
                            [item.id]: newValue.target.value,
                          }))
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            const text = e.currentTarget.value || ''
                            updateToDoText(item.id, text.trim())
                          }
                        }}
                        onBlur={(e) => {
                          const text = e.currentTarget.value || ''
                          updateToDoText(item.id, text.trim())
                        }}
                        // @william-stafflink: fix these props, they are non-optional
                        onResize={undefined}
                        onResizeCapture={undefined}
                      />
                      <IconButton
                        aria-label="delete"
                        className="invisible min-w-[0px] group-hover:visible"
                        onClick={() => updateToDoStatus(item.id, 'ARCHIVED')}
                      >
                        <TrashIcon className="h-5 w-5 text-red-500" />
                      </IconButton>
                    </ListItem>
                  ))}
              </List>
            )}
          </div>
        </Box>
      </Popover>
    </>
  )
}

export default ToDosList
